import * as React from "react"

const BtnSecondary = props => (
  <button
    className="flex items-center justify-center px-5 bg-[#666666] hover:bg-neutral-600 min-w-[230px] w-full max-w-[280px] md:max-w-[285px] min-h-[3.25rem] cursor-pointer mb-2.5 transition-all text-white"
    {...props}
  >
    <span className="font-bold">{props.cta}</span>
  </button>
)

export default BtnSecondary
